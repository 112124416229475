import React from 'react';
import { graphql, Link } from 'gatsby';
import { Story, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'insights';

export const query = graphql`
  query($id: String!) {
    contentfulPost(id: { eq: $id }) {
      metaTitle
      metaDescription
      category {
        name
      }
      headline
      date
      byline
      image {
        ...ImageSharpMedium
      }
      floatingCta {
        headline
        ctaText
        ctaLink {
          ...NavigatorCtaLink
        }
      }
      body {
        json
      }
      spacerNavigator {
        headline
        ctaText
        ctaLink {
          ...NavigatorCtaLink
        }
      }
      relatedHeader
      relatedStories {
        headline
        ctaText
        slug
        redirectUrl
        internal {
          type
        }
        image {
          ...ImageSharpStoryCard
        }
      }
      closingNavigator {
        headline
        ctaText
        ctaLink {
          ...NavigatorCtaLink
        }
      }
    }
  }
`;

export default function({ data }) {
  const { contentfulPost } = data;
  const doc = {
    meta: {
      title: contentfulPost.metaTitle,
      description: contentfulPost.metaDescription,
      image: contentfulPost.image && contentfulPost.image.localFile.childImageSharp.fluid.src,
    },
    header: {
      categoryName: contentfulPost.category && contentfulPost.category[0].name,
      headline: contentfulPost.headline,
      date: contentfulPost.date,
      authorName: contentfulPost.byline,
      background: 'dark-1',
    },
    floatingCta: contentfulPost.floatingCta && {
      headline: contentfulPost.floatingCta[0].headline,
      ctaText: contentfulPost.floatingCta[0].ctaText,
      ctaLink:
        contentfulPost.floatingCta[0].ctaLink &&
        linkResolver({
          type: contentfulPost.floatingCta[0].ctaLink[0].internal.type,
          slug:
            contentfulPost.floatingCta[0].ctaLink[0].redirectUrl ||
            contentfulPost.floatingCta[0].ctaLink[0].slug,
          postPrefix,
        }),
      background: 'accent-1',
      buttonColor: 'white',
    },
    image: contentfulPost.image,
    body: contentfulPost.body,
    spacerNavigator: contentfulPost.spacerNavigator && {
      headline: contentfulPost.spacerNavigator[0].headline,
      buttonText: contentfulPost.spacerNavigator[0].ctaText,
      buttonUrl:
        contentfulPost.spacerNavigator[0].ctaLink &&
        linkResolver({
          type: contentfulPost.spacerNavigator[0].ctaLink[0].internal.type,
          slug:
            contentfulPost.spacerNavigator[0].ctaLink[0].redirectUrl ||
            contentfulPost.spacerNavigator[0].ctaLink[0].slug,
          postPrefix,
        }),
      background: 'accent-1',
      primary: true,
      noRule: true,
    },
    closingBackground: 'white',
    relatedPosts: contentfulPost.relatedStories && {
      headline: contentfulPost.relatedHeader,
      cards: contentfulPost.relatedStories.map((card) => ({
        headline: card.headline,
        image: card.image,
        linkUrl: linkResolver({
          type: card.internal.type,
          slug: card.redirectUrl || card.slug,
          postPrefix,
        }),
        linkText: card.ctaText,
      })),
    },
    closingNavigator: contentfulPost.closingNavigator && {
      headline: contentfulPost.closingNavigator[0].headline,
      buttonText: contentfulPost.closingNavigator[0].ctaText,
      buttonUrl:
        contentfulPost.closingNavigator[0].ctaLink &&
        linkResolver({
          type: contentfulPost.closingNavigator[0].ctaLink[0].internal.type,
          slug:
            contentfulPost.closingNavigator[0].ctaLink[0].redirectUrl ||
            contentfulPost.closingNavigator[0].ctaLink[0].slug,
          postPrefix,
        }),
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <Story doc={doc} Link={Link} />
    </Layout>
  );
}
